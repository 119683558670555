<template>
    <div id='aplayer'></div>
</template>

<script>
import 'aplayer/dist/APlayer.min.css';

import APlayer from 'aplayer';

export default {
    name: 'Aplayer',
    props: {
        list:{
            type:Array,
            default:[]
        }

    },
    async mounted() {

        const ap = new APlayer({
            container: document.getElementById('aplayer'),
            listFolded: true,
            listMaxHeight: "99px",
            loop: 'all',
            order: 'random',
            preload: 'auto',
            audio: this.list
        });
    }
}
</script>
<style scoped>

</style>
