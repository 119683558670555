<template>
   <v-calendar style = 'width:100%' :attributes = 'attrs'></v-calendar>
</template>

<script>
export default {
  name: 'calendar',
  data() {
      return {
          attrs:[
                {
                key:'today',
                dates:new Date(),
                highlight:true,
                popover:{
                    label:'要加油哦~'
                }
            }

            ]
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
