<template>
    <div class = 'blogBox clearfix'>
        <div class="blogbox_left" v-if = '$store.state.width > 765'>
            <Mes/>
            <Aplayer :list = 'musicList' v-if = 'musicList.length'/>

        </div>
        <div class="blogbox_center">
            <transition name="slide-left">
                <router-view></router-view>
            </transition>
        </div>
       <!-- <div class="blogbox_right" v-if = '$store.state.width > 765'>
            <Clock/>
            
        </div> -->

    </div>
</template>
<script>
import Mes from './asideMes'
// import Clock from '@/components/clock'
import Aplayer from '@/components/aplayer'
import { mapState } from 'vuex'
import {getMusicList} from '@/api/common/userinfo.js'
export default {
    data:() => {
        return {
			file:"",
            musicList:[]
        }
    },
    computed:{
        ...mapState(['width'])
    },
    components:{
        Mes,
        // Clock,
        Aplayer
    },
    mounted() {
        //获取背景音乐
        this.getAPlayerList();
        let name = 'lanmo';
        console.log("🚀 ~ file: index.vue:45 ~ mounted ~ name:", name)
    },
    methods:{
        async getAPlayerList(){
            let list = await getMusicList({
                pageSize:999,
                status:1
            })
            if(list.data.list&&list.data.list.length){
                this.musicList = list.data.list
            }
        },
	}
}
</script>
<style lang="less" scoped>
.blogBox{
    min-height: 630px;
    position: relative;
    .blogbox_left{
        width:275px;
        float:left;
        position: -webkit-sticky;
        position: sticky;
        margin-right: 25px;
        top:70px;
    }
    .blogbox_center{
        width:calc(100% - 300px);
        float:left;
        min-height: 100px;
    }
    .blogbox_right{
        width:200px;
        float:left;
        position: -webkit-sticky;
        position: sticky;
        top: 70px;
        margin-left: 25px
    }
    @media screen and (max-width: 765px){
        .blogbox_center{
            width: 100%;
        }
        
    }
}

.slide-left-enter-active,
.slide-left-leave-active {
    will-change: transform;
    transition: all 500ms;
    position: absolute;
}

.slide-left-enter {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
}
.slide-left-leave-active {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
}
</style>