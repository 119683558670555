<template>
    <div>
        <div class="username handle" >
            风来过丶凌乱了悲伤
        </div>
        <div style='width:120px;margin: 25px auto 0;'>
            <mu-avatar :size="size">
                <img src="../../assets/images/head.jpg" alt="">
            </mu-avatar>
        </div>
        <div class="des">
        越努力，越幸运~
        </div>
        <Calendar/>
    </div>
</template>
<script>
import Calendar from "@/components/calendar"
export default {
    data() {
        return {
            size: 120,
        }
    },
    components: {
        Calendar
    },
    methods:{
    }
}
</script>
<style lang="less" scoped>
.username {
    padding: 20px 0;
    background-color: #222;
    color: #fff;
    text-align: center;
    font-size: 20px;
}

.des {
    font-size: 14px;
    color: #aaa;
    text-align: center;
    margin-top: 10px;
    padding: 0 20px;
    position: relative;
    margin-bottom: 20px;

}
</style>